@import "../../styles/colors";
@import "../../styles/fonts";

$gift-image-width: 115px;
$gift-image-height: $gift-image-width;

.gift-card {
  width: 100%;
  display: flex;

  border-radius: 8px;
  border: 1px solid $purple-5;
  background: $white;

  .gift-image {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;

    border-right: 1px solid $purple-5;

    width: $gift-image-width;
    height: $gift-image-height;

    background-size: cover;
    background-position-x: center;
    background-position-y: center;
  }

  .gift-data {
    width: calc(100% - $gift-image-width);
    padding: 8px 8px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .gift-data-top {
    .gift-name {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      width: 100%;

      color: $text-1;
      font-family: $montserrat;
      font-size: 14px;
      font-weight: 400;

      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .gift-data-bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .gift-link {
      backdrop-filter: none;
      box-shadow: none;

      padding: 4px 8px;

      border-radius: 8px;
      border: 1px solid $text-6;
      background: $white;

      color: $text-4;
      font-family: $montserrat;
      font-size: 10px;
      font-weight: 600;

      img {
        margin-right: 4px;
      }
    }

    .gift-details {
      display: flex;
      flex-direction: column;

      .gift-details-label {
        color: $text-5;
        font-family: $montserrat;
        font-size: 10px;
        font-weight: 600;
      }

      .gift-details-info {
        color: $gray-4;
        font-family: $montserrat;
        font-size: 11px;
        font-weight: 500;
      }
    }
  }
}