@import "../../styles/colors";
@import "../../styles/fonts";

.gift-type {
  width: fit-content;
  padding: 2px 6px;
  margin-bottom: 4px;
  border-radius: 8px;

  text-transform: uppercase;
  font-family: $montserrat;
  font-size: 8px;
  font-weight: 600;

  user-select: none;

  &.tag-shadow {
    filter: $drop-shadow-small;
  }

  &.tag-clickable {
    cursor: pointer;
  }

  &.tag-all {
    background-color: #EBEBEB;
    color: #2F2C32;

    &.tag-active {
      background-color: #59565D;
      color: #EBEBEB;
    }
  }

  &.tag-common {
    background-color: #FFECED;
    color: #B50076;

    &.tag-active {
      background-color: #BB308A;
      color: #FFECED;
    }
  }

  &.tag-super {
    background-color: #FAE0CD;
    color: #BA601E;

    &.tag-active {
      background-color: #BE7743;
      color: #FAE0CD;
    }
  }

  &.tag-mega {
    background-color: #E3E8E5;
    color: #0B2B24;

    &.tag-active {
      background-color: #28554B;
      color: #E3E8E5;
    }
  }
}