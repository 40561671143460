@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";

@import "../../styles/colors";
@import "../../styles/fonts";

$modal-margin-y: 25px;

.modal-wrapper {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.modal-content {
  margin: $modal-margin-y auto;
  padding: 20px;
  border: 1px solid $white;
  height: calc(100% - $modal-margin-y - $modal-margin-y);

  width: 90%;
  @include media-breakpoint-up(lg) {
    width: 50%;
  }
  max-width: 486px !important;

  background: linear-gradient(0deg, rgba(250, 223, 225, 0.2), rgba(250, 223, 225, 0.2)), linear-gradient(105.89deg, rgba(255, 255, 255, 0.8) 19.8%, rgba(255, 255, 255, 0.4) 84.39%);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(7.5px);
  border-radius: 16px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-close {
  position: absolute;
  z-index: 20;
  right: 4%;
  top: 3%;

  font-family: $montserrat;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: $text-6;

  &:hover, &:focus {
    text-decoration: none;
    user-select: none;
    cursor: pointer;
  }
}

.modal-title {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 36px;
    height: 36px;

    margin-bottom: 12px;
  }

  h3 {
    font-family: $montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    color: $text-2;
  }
}

.modal-text {
  width: 100%;
}

.modal-buttons {
  button:not(:last-child) {
    margin-bottom: 16px;
  }
}

.specific-modal {
  h2 {
    color: $gray-3;
    font-family: $montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    margin: 0;
  }

  h4 {
    color: $green-1;
    font-family: $montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
  }

  h5 {
    color: $gray-3;
    font-family: $montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }

  p {
    color: $gray-3;
    font-family: $montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;

    margin: 0;
  }
}