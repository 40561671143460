@import "../../styles/colors";
@import "../../styles/fonts";

.side-icon-wrapper {
  display: flex;

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .icon {
    width: 32px;
    height: 32px;

    margin-right: 12px;

    &.big-icon {
      width: 72px;
      height: 72px;
    }
  }

  .content {
    width: 100%;
  }
}