@import "bootstrap";

@import "../../styles/colors";
@import "../../styles/fonts";

.intro-page {
  height: 100vh;

  .flowers {
    position: fixed;
    z-index: -10;

    width: 100%;
    height: 100%;

    .top {
      position: fixed;

      height: 35%;
      top: -10%;
      left: -30%;

      @include media-breakpoint-up(md) {
        top: -12%;
        left: -20%;
      }

      @include media-breakpoint-up(lg) {
        top: -12%;
        left: -4%;
      }

    }

    .bottom {
      position: fixed;
      direction: rtl;

      height: 45%;
      top: 70%;
      right: -10%;

      @include media-breakpoint-up(md) {
        top: 70%;
        right: -10%;
      }

      @include media-breakpoint-up(lg) {
        top: 70%;
        right: -4%;
      }

    }
  }

  .content-wrapper {
    height: 100%;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .welcome {
      font-family: $montserrat;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: $text-1;
    }

    .names {
      font-family: $selima;
      font-weight: 400;
      font-size: 90px;
      text-align: center;
      line-height: 80px;

      color: $green-1;
      filter: $drop-shadow;
    }
  }
}

