$text-1: #201336;
$text-2: #35264B;
$text-3: #4B3B62;
$text-4: #615079;
$text-5: #796791;
$text-6: #917EAA;

$green-1: #3E4B43;

$gray-3: #434147;
$gray-4: #59565D;
$gray-6: #87848B;
$gray-translucid: rgba(249, 249, 249, 0.40);

$pink-3: #FABBD9;

$purple-3: #D87DFF;
$purple-5: #EAB7FF;
$purple-6: #EFCEFF;

$back-5: #FCE4F8;

$white: #F9F9F9;

$gradient-bg: linear-gradient(163.2deg, rgba(250, 187, 217, 0.4) 0%, rgba(197, 183, 212, 0.4) 98.94%), #FFFFFF;
$drop-shadow: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.25));
$drop-shadow-small: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.25));