@import "../../styles/colors";
@import "../../styles/fonts";

.button-big {
  padding: 20px;
  border-radius: 16px;
  border: 1px solid;

  font-family: $montserrat;
  font-weight: 500;
  font-size: 14px;

  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);

  display: flex;
  align-items: center;
  justify-content: center;

  .button-content {
    display: flex;
    align-items: center;
  }

  &:hover {
    font-weight: 700;

    box-shadow: 0px 4px 20px 5px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(10px);
  }

  &.glass {
    border-image-source: linear-gradient(99.33deg, rgba(255, 255, 255, 0.4) 4.48%, rgba(255, 255, 255, 0.1) 90.81%);
    background: linear-gradient(105.89deg, rgba(255, 255, 255, 0.6) 19.8%, rgba(255, 255, 255, 0.2) 84.39%);
    color: $text-1;
  }

  &.white {
    border-color: $pink-3;
    background-color: $white;
    color: $text-3;
  }

  &.only-border {
    border-color: $pink-3;
    background-color: transparent;
    color: $text-3;
  }

  &.big-text {
    font-size: 20px;
  }

  p {
    margin: 0;
  }

  .button-icon {
    width: 36px;
    height: 36px;
  }
}

