@import "../../styles/colors";
@import "../../styles/fonts";

.gifts-page {
  height: 100vh;
  width: 100%;

  padding-top: 10px;
  padding-bottom: 20px;

  .back-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-bottom: 8px;

    .back {
      padding: 0;
      border: 0;
      background-color: transparent;
      box-shadow: none;

      color: $text-4;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;

      img {
        width: 14px;
        height: 14px;

        margin-right: 4px;
      }
    }
  }

  .gifts-wrapper {
    height: 100%;
    padding-bottom: 30px;

    .title {
      display: flex;
      flex-direction: column;
      align-items: center;

      margin-bottom: 10px;

      img {
        width: 36px;
        height: 36px;

        margin-bottom: 12px;
      }

      h3 {
        font-family: $montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;

        color: $text-2;

        margin-bottom: 0px;
      }
    }

    .filter-wrapper {
      display: flex;
      justify-content: space-evenly;

      margin-bottom: 8px;
    }

    .list-wrapper {
      .gift-card {
        margin-bottom: 8px;
      }
    }

    .pagination-wrapper {
      margin-top: 16px;

      display: flex;
      justify-content: center;
      align-items: center;

      span {
        margin-left: 12px;
        margin-right: 12px;

        font-size: 12px;
        font-weight: 800;
        font-family: $montserrat;
        color: $text-2;
      }
    }
  }
}