@import "../../styles/colors";
@import "../../styles/fonts";

.menu-page {
  height: 100vh;
  width: 100%;

  .flower-wrapper {
    display: flex;
    justify-content: center;

    position: sticky;
    width: inherit;
    z-index: -15;

    .flower {
      position: fixed;
      z-index: -10;

      height: 65%;
      bottom: -3%;
    }
  }

  .menu-content {
    height: 100%;

    padding-top: 50px;
    padding-bottom: 50px;

    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .menu-header {
      display: flex;
      flex-direction: column;

      .icon-wrapper {
        display: flex;
        justify-content: center;

        margin-bottom: 12px;

        .header-icon {
          width: 52px;
          height: 52px;
        }
      }

      .names {
        text-align: center;
        margin-top: 12px;
        margin-bottom: 12px;

        font-family: $selima;
        font-style: normal;
        font-weight: 400;
        font-size: 56px;
        line-height: 64px;
        color: $text-3;
      }
    }

    .button-list-wrapper {
      .button-content-wrapper {
        display: flex;
      }

      button {
        margin-top: 32px;
        max-width: 280px;
        width: 100%;
      }

     .odd-button {
        justify-content: flex-start;
      }

      .even-button {
        justify-content: flex-end;
      }
    }
  }
}