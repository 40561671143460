@import "../../styles/colors";
@import "../../styles/fonts";

$line-padding: 8px;

.line-text-wrapper {
  display: flex;
  justify-content: space-between;

  .line-wrapper {
    color: $gray-6;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;

    &:first-child {
      padding-right: $line-padding;
    }

    &:last-child {
      padding-left: $line-padding;
    }

    &.pink-line {
      color: $pink-3;
    }

    &.purple-line {
      color: $purple-6;
    }

    hr {
      margin: 0;
      opacity: 1;
    }
  }

  .text {
    flex-shrink: 0;

    font-family: $montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: $gray-6;

    &.big-text {
      font-size: 16px;
    }
  }
}