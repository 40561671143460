@import "../../styles/colors";
@import "../../styles/fonts";

.button-small {
  padding: 4px 8px;
  height: fit-content;

  border-radius: 8px;
  border: 1px solid $text-6;
  background: $white;

  color: $text-4;
  font-family: $montserrat;
  font-size: 10px;
  font-weight: 600;

  img.before {
    margin-right: 4px;
  }

  img.after {
    margin-left: 4px;
  }

  &.disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}