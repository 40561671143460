@import "bootstrap";

@import "./styles/colors";
@import "./styles/fonts";

body {
	background: $gradient-bg;
}

html, body, .container {
	height: 100%
}

.container {
	max-width: 540px !important;
}